@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@import "mixins";

:root {
  --container-width: 1315px;
  --inner-container-width: 1040px;
  --resource-container-width: 910px;
  --container-padding: 15px;
  --bunov-black: #182210;
  --bunov-orange: hsl(29, 71%, 51%);
  --bunov-orange-hover: #d2682c;
  --bunov-green: #3e5529;
  --bunov-white: #fcfcfc;
  --bunov-grey: #f3f3f3;
  --bunov-dark-grey: #8b997f;
  --bunov-beige: #e8e8e4;
  --bunov-purple: #5545a3;

  @include mobile {
    --container-padding: 24px;
  }

  @include tablet-portrait {
    --container-padding: 24px;
  }
}

*,
*::before,
::after {
  box-sizing: border-box;
  margin-block: 0;
}

html {
  font-size: 10px;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
  min-height: 100vh;
  color: var(--bunov-black);
}

body {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: var(--bunov-black);
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }
}

img {
  max-width: 100%;

  &.dropshadow {
    box-shadow: 0px 4px 40px rgba(24, 34, 16, 0.24);
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    flex: 1;
    scroll-behavior: smooth;
  }
}

sup {
  line-height: 0;
}

svg {
  pointer-events: none;
}

.container {
  width: calc(var(--container-width) + calc(var(--container-padding) * 2));
  padding: 0 var(--container-padding);
  margin: 0 auto;
  max-width: 100%;
}

.inner-container {
  width: calc(
    var(--inner-container-width) + calc(var(--container-padding) * 2)
  );
  padding: 0 var(--container-padding);
  margin: 0 auto;
  max-width: 100%;
}

.resource-container {
  width: calc(
    var(--resource-container-width) + calc(var(--container-padding) * 2)
  );
  padding: 0 var(--container-padding);
  margin: 0 auto;
  max-width: 100%;
}

.btn {
  padding: 1.6rem 2.4rem;
  border: 1px solid;
  border-radius: 0.4rem;
  font-weight: 600;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &--primary {
    background-color: var(--bunov-orange);
    border-color: var(--bunov-orange);
    color: var(--bunov-white);

    &:hover {
      background-color: var(--bunov-orange-hover);
      border-color: var(--bunov-orange-hover);
    }
  }

  &--outline {
    background-color: transparent;
    color: var(--bunov-white);
    border-color: var(--bunov-white);

    &:hover {
      background-color: var(--bunov-orange);
      border-color: var(--bunov-orange);
      color: var(--bunov-white);
    }
  }

  &--secondary {
    background-color: var(--bunov-purple);
    color: var(--bunov-white);
    border-color: var(--bunov-purple);

    &:hover {
      background-color: #4d3e93;
    }
  }

  &--icon {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &[disabled] {
    background-color: #b8bbb5;
    color: rgba(252, 252, 252, 0.5);
    border-color: rgba(252, 252, 252, 0.5);
    cursor: not-allowed;
  }
}

.icon {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: rgba(62, 85, 41, 0.1);
  border: 1px solid var(--bunov-green);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide-desktop {
  display: none;
}

@include tablet-portrait {
  .hide-desktop {
    display: initial;
  }

  .hide-mobile {
    display: none;
  }
}

body {
  &.site-hcp {
    @import "hcp";
  }

  &.site-patient {
    @import "patient";
  }
}

@import "components/sections";
@import "components/resources";
@import "components/footer";
@import "components/hcp-popup";
@import "components/external-popup";
@import "components/cookie-popup";
@import "components/hcp/cta";

.grecaptcha-badge {
  visibility: hidden;
}
