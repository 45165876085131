footer {
  height: 21.5rem;
  background-color: var(--bunov-white);
  display: flex;
  line-height: 1.6rem;
  flex-direction: column;
  border-top: 1px solid var(--bunov-grey);

  .footer__top {
    flex: 1;
    font-size: 1.2rem;

    .container {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-between;
    }

    &__left {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .top {
        display: flex;
        align-items: center;
        color: var(--bunov-black);
        gap: 2rem;

        a {
          display: block;
          color: var(--bunov-black);
          text-decoration: underline;
          margin-top: 0.5rem;
        }
        & > img {
          max-width: 105px;
        }
      }

      .bottom {
        ul {
          display: flex;
          padding: 0;
          list-style-type: none;

          li {
            a {
              color: var(--bunov-black);
              text-decoration: underline;
            }

            &:after {
              content: "|";
              color: var(--bunov-black);
              margin: 0 1rem;
            }

            &:last-of-type {
              &:after {
                content: "";
              }
            }
          }
        }
      }
    }

    &__right {
      .side-effects {
        border: 1px solid var(--bunov-black);
        font-weight: 700;
        font-size: 1.2rem;
        max-width: 545px;
        width: 100%;
        text-align: center;
        padding: 1.2rem;
        color: var(--bunov-black);

        span {
          white-space: nowrap;
        }

        a {
          text-decoration: none;
          color: var(--bunov-black);
        }

        p:first-of-type {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .footer__bottom {
    height: 4rem;
    background-color: var(--bunov-black);
    color: #fff;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: normal;

    .container {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }

    &__left {
      display: flex;
      align-items: center;

      .copy {
        text-transform: uppercase;
      }

      .pipe {
        width: 0.2rem;
        height: 45%;
        background-color: #f3f3f3;
        margin: 0 1.7rem;
        opacity: 0.2;
      }

      .dop {
        margin-left: 1.7rem;
      }
    }

    &__right {
      ul {
        display: flex;
        padding: 0;
        list-style-type: none;
        gap: 1.7rem;
        height: 100%;
        align-items: center;

        li {
          a {
            color: #fff;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  @include tablet-portrait {
    height: auto;
    padding-top: 3.2rem;

    .footer__top {
      .container {
        flex-direction: column;
      }

      &__left {
        .top {
          gap: 2.5rem;
        }

        .bottom {
          ul {
            // display: grid;
            // grid-template-columns: repeat(2, 1fr);
            // grid-template-rows: repeat(2, 1fr);
            // grid-column-gap: 0;
            // grid-row-gap: 0.5rem;
            display: flex;
            flex-direction: row;
            gap: 1rem;
            flex-wrap: wrap;
            margin-bottom: 2rem;

            li {
              &:first-of-type {
                grid-area: 1 / 1 / 2 / 3;
                &:after {
                  display: none;
                }
              }
              &:nth-of-type(2) {
                &:after {
                  margin-right: 3px;
                }
              }
              &:nth-of-type(3) {
                grid-area: 2 / 2 / 3 / 3;
              }
            }
          }
        }
      }

      &__right {
        .side-effects {
          margin-bottom: 2rem;
        }
      }
    }
  }

  @include mobile {
    .footer__bottom {
      font-size: 1rem;
      height: auto;
      padding: 2.5rem 0;

      .container {
        flex-direction: column;
      }

      &__left {
        justify-content: space-between;

        .pipe {
          height: 1.6rem;
        }
      }

      &__right {
        margin-top: 1rem;
        ul {
          font-size: 1.2rem;
          justify-content: space-between;
        }
      }
    }
  }
}

section.section--report {
  .pdf-report {
    width: 802px;
    max-width: 100%;
    height: calc(100vh - 4rem - 8rem);
    overflow-y: scroll;
    margin: 0 auto;

    canvas {
      max-width: 100%;
      &:first-of-type {
        display: none;
      }
    }

    @include tablet-portrait {
      height: calc(100vh - 8rem - 8rem);
    }
  }
}

main.single-par {
  section.section {
    padding: 0;
    height: 100%;
    padding-top: 4rem;
  }

  & ~ footer {
    height: 4rem;
    .footer__top {
      display: none;
    }

    @include tablet-portrait {
      padding-top: 0;
      height: 13rem;
      z-index: 2;

      .footer__bottom {
        height: 100%;
      }
    }
  }

  @include tablet-portrait {
    flex: initial !important;
    height: calc(100vh - 130px);
  }
}
