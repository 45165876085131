.hero {
  position: relative;
  background-color: var(--bunov-green);
  padding: 8rem 0;
  margin-bottom: 80px;
  color: var(--bunov-white);
  font-size: 1.6rem;
  line-height: 2.2rem;

  .inner-container {
    display: flex;
    gap: 6rem;

    @include mobile {
      flex-direction: column;
    }
  }
  .left {
    flex: 1 0;
  }

  .right {
    flex: 0.7 0;
  }

  h1 {
    font-size: 4.8rem;
    line-height: 5.8rem;
    font-weight: 700;
  }

  p {
    font-weight: 400;

    margin-top: 2.1rem;
    margin-bottom: 3.5rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    li {
      display: flex;
      gap: 2.4rem;
      align-items: center;

      .icon {
        width: 6rem;
        height: 6rem;
        background-color: var(--bunov-orange);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 50%;
        }
      }

      span {
        flex: 1;
      }
    }
  }

  .image-wrapper {
    position: absolute;
    display: flex;
    top: 0;

    img {
      object-fit: cover;

      &:first-of-type {
        border-bottom-left-radius: 0.8rem;
      }
    }
  }

  .hero-home-circles {
    position: absolute;
    top: 0;
    left: calc(25% + 8rem);
    max-height: 60%;

    @include mobile {
      display: none;
    }
  }

  .mobile-circles {
    position: absolute;
    top: 0;
    right: 0;
  }

  &--initiating {
    p {
      margin-bottom: 0;

      a {
        text-decoration: underline;
      }
    }
    svg {
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      transform: translateX(-50%);
    }

    .hero-image {
      position: absolute;
      border-radius: 0.8rem 0 0 0.8rem;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.3);
      object-fit: cover;
    }
  }

  @include mobile {
    padding: 2.4rem 0;

    .inner-container {
      gap: 0;
      margin-bottom: 28.4rem;
    }

    h1 {
      font-size: 3.2rem;
      line-height: 4rem;
    }

    ul {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    .image-wrapper {
      top: calc(100% - 28.4rem);
      height: auto;

      img {
        object-position: top;

        &:first-of-type {
          border-top-left-radius: 0.5rem;
        }
      }
    }

    &--initiating {
      margin-bottom: 0;
      z-index: 2;
      .hero-image {
        bottom: -2rem;
      }
    }
  }
}
