.cookie-popup {
  align-items: center !important;

  h6 {
    font-size: 1.6rem;
    margin: 0;
  }

  p {
    font-size: 1.2rem;
    margin-top: 0.3rem;

    &.bold {
      font-weight: 700;
    }
  }

  div:first-of-type {
    margin: 0;
  }

  div:nth-of-type(2) {
    height: 100%;
  }
}
