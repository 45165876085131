h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Noto Serif", serif;
  font-weight: 700;
}

h1 {
  font-size: 4rem;
  line-height: 4.8rem;

  @include tablet-portrait {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }
}

h2 {
  font-size: 3.2rem;
  line-height: 3.6rem;
}

h3 {
  font-size: 2.4rem;
  line-height: 3.2rem;
}

sup {
  line-height: 0;
  font-size: 60%;
}

.tagline {
  color: var(--bunov-dark-grey);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 2.4rem;
  letter-spacing: 1px;

  &-pointer {
    --line-width: 100px;

    position: absolute;
    top: 0;
    right: 0;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 1px solid var(--bunov-dark-grey);

    &:before {
      content: "";
      position: absolute;
      width: var(--line-width);
      height: 1px;
      background-color: var(--bunov-dark-grey);
      top: 4px;
      right: 100%;
    }
  }

  @include tablet-portrait {
    &-pointer {
      display: none;
    }
  }
}

.body-opacity {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

&.menu-open {
  .body-opacity {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #e8e8e4;
    opacity: 0.6;
    z-index: 100;
  }
}

@import "components/patient/header";
@import "components/patient/sections";
@import "components/patient/heading";
