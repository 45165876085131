.sticky-head {
  position: sticky;
  top: 0;
  z-index: 202;
}

.header__notice {
  width: 100%;
  height: 4rem;
  background-color: var(--bunov-black);
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    p {
      margin: 0;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.2rem;
      line-height: 1;
      letter-spacing: 0.1em;

      a {
        color: #fff;
        margin-left: 10px;
        text-decoration: underline;
      }
    }
  }
  @include tablet-portrait {
    height: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    letter-spacing: 0.1rem;

    .container {
      flex-direction: column;
      text-align: center;

      p {
        line-height: 1.6rem;
        font-size: 1rem;
        a {
          display: block;
          margin-top: 0.3rem;
        }

        &:after {
          display: none;
        }
      }

      ul {
        flex-direction: column;
        font-size: 1rem;
        row-gap: 1rem;

        li {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.header__links {
  height: 4rem;
  background-color: #e8e8e4;
  display: flex;
  align-items: center;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    li {
      a {
        font-weight: 400;
        font-size: 1.2rem;
        color: #182210;
        position: relative;
        margin-right: 1.5rem;
        text-decoration: none;

        &::after {
          content: "|";
          margin-left: 1.5rem;
          text-decoration: none;
          font-weight: 400;
        }

        span {
          text-decoration: underline;
        }
      }

      &:last-of-type {
        a {
          margin-right: 0;

          &::after {
            content: "";
            margin-left: 0;
          }
        }
      }
    }
  }

  @include tablet-portrait {
    ul {
      justify-content: center;
    }
  }
}

header {
  position: relative;
  z-index: 101;

  .header__main {
    width: 100%;
    height: 12.4rem;
    background-color: var(--bunov-white);
    border-bottom: 1px solid var(--bunov-beige);

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }

    &__logo {
      img {
        max-width: 25rem;
      }
    }

    nav {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        display: flex;
        gap: 5.6rem;
        justify-content: flex-end;

        li {
          a {
            font-size: 1.6rem;
            text-decoration: none;
            color: #182210;

            &.active {
              font-weight: 700;
            }
          }
        }
      }
    }

    @include tablet-portrait {
      height: 8rem;

      &__logo {
        img {
          max-width: 13.6rem;
        }
      }

      nav {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 100;
        background-color: var(--bunov-white);
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease;

        ul {
          width: 100%;
          gap: 0;
          @include tablet-portrait {
            flex-direction: column;
          }
          li {
            width: 100%;
            height: 10rem;
            a {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            }

            &:after {
              content: "";
              width: 100%;
              height: 1px;
              background-color: #90948b;
              display: block;
              // margin: 0 0 2rem;
              opacity: 0.1;
            }

            &:last-of-type {
              &:after {
                display: none;
              }
            }
          }
        }
      }

      .burger-menu {
        display: block;
        width: 4rem;
        height: 2.4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        transition: align-items 0.3s ease;

        span {
          width: 100%;
          height: 0.4rem;
          background-color: #182210;

          &:nth-of-type(2) {
            width: 3.2rem;
          }

          &:nth-of-type(3) {
            width: 2.4rem;
          }
        }

        &.open {
          align-items: flex-start;

          & ~ nav {
            height: 40.5rem;
          }
        }
      }
    }
  }

  .indicator {
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    width: 100%;

    &__ball {
      position: absolute;
      width: 1rem;
      height: 1rem;
      background-color: #5545a3;
      border-radius: 50%;
      transition: all 0.3s ease;

      &--hide {
        opacity: 0;
      }
    }

    &__line {
      height: 1px;
      width: 500px;
      position: absolute;
      bottom: -0.5rem;
      right: 0;
      background-color: #5545a3;
      transition: all 0.3s ease;

      &--hide {
        opacity: 0;
      }
    }

    @include tablet-portrait {
      display: none;
    }
  }
}
