.resource {
  position: relative;
  display: flex;
  gap: 2.4rem;
  border-bottom: 1px solid rgba(24, 34, 16, 0.05);
  padding-bottom: 2.7rem;
  margin-bottom: 2.7rem;

  & * {
    pointer-events: none;
  }

  .data {
    flex: 1;

    .title {
      font-size: 1.8rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    .description {
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-weight: 400;
    }
  }

  .action-icon {
    display: flex;
    align-items: center;

    span {
      display: none;
    }
  }

  @include mobile {
    flex-direction: column;
    gap: 1.5rem;

    .action-icon {
      span {
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--bunov-orange);
        display: inline-block;
        margin-right: 1rem;
      }
    }
  }
}

body.site-patient {
  .resource {
    .icon {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .resource-container {
    h2 {
      font-family: "Inter", sans-serif;
      margin-bottom: 3rem;
    }
  }
}

body.site-patient {
  .resource {
    .action-icon {
      span {
        color: var(--bunov-purple);
      }
    }
  }
}
