.section {
  position: relative;
  padding: 10rem 0;
  font-size: 1.6rem;

  h2 {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 3.6rem;
    width: 100%;
    margin-bottom: 1.6rem;

    span {
      color: var(--bunov-green);
    }
  }

  p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.2rem;
    margin-bottom: 1rem;

    &.bold {
      font-weight: 700;
    }

    a {
      text-decoration: underline;
    }

    span {
      color: var(--bunov-orange);
    }
  }

  ul {
    margin-bottom: 1.5rem;
    li {
      font-size: 1.6rem;
      a {
        text-decoration: underline;
      }
    }
  }

  &--grey {
    background-color: var(--bunov-grey);
  }

  &--beige {
    background-color: var(--bunov-beige);
  }

  &--white {
    background-color: var(--bunov-white);
  }

  &.hcp-review {
    .green-background {
      img {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
    }
  }

  &--half-half {
    .inner-container,
    .container {
      display: flex;
      gap: 5rem;
      position: relative;

      @include phablet {
        flex-direction: column;
        gap: 5rem;

        &.mobile-reverse {
          flex-direction: column-reverse;
        }
      }

      .left,
      .right {
        flex: 1;

        &.align-end {
          position: relative;
          display: flex;
          justify-content: flex-end;
        }

        &.align-start {
          position: relative;
          display: flex;
          justify-content: flex-end;
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        align-items: flex-start;

        @include tablet-portrait {
          align-items: center;
        }

        .block {
          width: 426px;
          background-color: var(--bunov-white);
          font-size: 1.8rem;
          font-weight: 700;
          line-height: 2.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2.2rem 0;
          flex-direction: column;
          text-align: center;
          position: relative;

          &--large {
            padding: 2.2rem 10rem;

            img {
              max-width: 100%;
              margin-top: 2rem;
            }
          }

          .icon {
            position: absolute;
            width: 4rem;
            height: 4rem;
            background-color: #eedccb;
            border: 1px solid var(--bunov-orange);
            border-radius: 50%;
            bottom: -2.6rem;
            z-index: 5;
            box-shadow: 0px 0px 0px 0.7rem var(--bunov-grey);
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              display: block;
              max-width: 40%;
            }
          }
        }
      }

      .single-image {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
          box-shadow: 0px 4px 24px rgba(24, 34, 16, 0.2);
          border-radius: 8px;
        }
      }
    }

    &.left-middle {
      .left {
        justify-content: center;
      }
    }

    &-green-overlap {
      .inner-container {
        gap: 10rem;
      }
      .left {
        position: relative;
        z-index: 2;
        color: var(--bunov-white);
        justify-content: center;

        p,
        img {
          max-width: 36rem;
        }
      }
      position: relative;
      .green-overlap {
        position: absolute;
        top: -3.5rem;
        left: -2.5rem;
        width: 53%;
        height: 96%;
        background-color: var(--bunov-green);
      }
    }
  }

  &.increase-padding {
    padding-bottom: 17rem;

    @include mobile {
      padding-bottom: 5rem;
    }
  }

  &--full-width {
    text-align: center;

    p {
      margin: 0;
    }
  }

  &--prescribe {
    h2 {
      // width: 110%;

      @include mobile {
        max-width: 100%;
      }
    }
  }

  &--contraindications {
    h2 {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 3rem;
    }

    ul {
      padding: 0;
      text-align: left;
      display: grid;
      column-gap: 4.5rem;
      row-gap: 2rem;
      grid-template-columns: repeat(auto-fill, minmax(10rem, 41rem));
      justify-content: center;
      list-style: none;

      li {
        position: relative;
        padding-left: 2rem;
        &:before {
          position: absolute;
          left: 0;
          content: "";
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          border: 1px solid rgba(0, 0, 0, 0.2);
          top: 0.7rem;
        }
      }
    }
  }

  &--steps {
    .step-block-container {
      justify-content: center;
      width: 100%;
      margin: 3.2rem 0;

      display: grid;
      gap: 0.8rem;
      grid-template-columns: repeat(auto-fill, minmax(10rem, 20rem));

      @include tablet-portrait {
        grid-template-columns: 20rem;
      }

      .step-block {
        height: 19rem;
        background-color: var(--bunov-white);
        display: flex;
        flex-direction: column;

        .letter {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          .letter-inner {
            width: 6rem;
            height: 6rem;
            background: rgba(62, 85, 41, 0.1);
            color: var(--bunov-green);
            font-size: 3.2rem;

            border: 1px solid var(--bunov-green);
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            display: flex;
          }
        }

        p {
          flex: 1;
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
      }

      & ~ p.bold br {
        display: none;
      }
    }
  }

  &--references {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 3rem 0;

    &.with-divider {
      border-top: 1px solid var(--bunov-grey);
    }

    h6 {
      font-size: 1.6rem;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }

    ol {
      padding-left: 1.3rem;

      li {
        a {
          line-break: anywhere;
          text-decoration: underline;
        }
      }

      & ~ p {
        font-size: 1.4rem;
        margin-top: 2rem;
      }
    }
  }

  &--abbreviations {
    padding-top: 6rem;
    padding-bottom: 0;

    p {
      margin-bottom: 0;
    }
  }

  &--resources {
    h2 {
      margin-bottom: 2.5rem;
    }

    .resource {
      &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  .green-block {
    width: 100%;
    background-color: var(--bunov-green);
    color: var(--bunov-white);
    padding: 8rem 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;
    position: relative;

    svg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    p,
    a {
      position: relative;
      z-index: 2;
    }
  }

  .tall-block-container {
    display: flex;
    gap: 2.5rem;

    .tall-block {
      background-color: var(--bunov-white);
      flex: 1;
      padding: 4rem 3rem;
      text-align: left;
      .icon {
        margin-bottom: 1.6rem;
      }

      p {
        margin-bottom: 1.5rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  @include phablet {
    padding: 6rem 0;

    h2 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    &--half-half {
      .inner-container {
        .left {
          .block {
            width: 100%;
          }
        }

        .right {
          &.align-end {
            justify-content: flex-start;
          }
        }
      }
    }

    &--considerations {
      padding: 3rem 0;
      .inner-container {
        flex-direction: column-reverse;
      }
    }

    &--abbreviations {
      padding-bottom: 0;
    }

    .tall-block-container {
      flex-direction: column;
    }
  }

  &-active-manage {
    @include tablet-portrait {
      padding: 0;
      .green-overlap {
        display: none;
      }

      .inner-container {
        padding: 0;
        gap: 3rem;

        .left {
          background-color: var(--bunov-green);
          padding: 0 var(--container-padding);
          padding-bottom: 2rem;
          padding-top: 4rem;
        }

        .right {
          padding: 0 var(--container-padding);
          padding-bottom: 2rem;
        }
      }
    }
  }
}

.product-list {
  display: flex;
  justify-content: center;
  gap: 0.9rem;
  margin: 3.2rem 0;

  .prod-wrap{
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
  }

  img {
    max-width: 24rem;
  }

  @include mobile {
    flex-direction: column;
    align-items: center;

    img {
      max-width: 68%;
    }
  }
}

.product-graph {
  max-width: 45.3rem;

  @include mobile {
    max-width: 100%;
  }
}

.couple-image {
  width: 35.4rem;
  height: 35.4rem;
  position: relative;
  z-index: 2;

  &.man {
    border-radius: 8px;
  }

  @include mobile {
    width: 34.4rem;
    height: 34.4rem;
    margin-bottom: 5rem;
  }
}

.green-background {
  position: absolute;
  background-color: var(--bunov-green);
  top: -17rem;
  height: calc(100% + 17rem + 10rem);
  z-index: 0;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  img {
    max-width: 42rem;

    object-fit: contain;

    &.mobile-circles {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &--left {
    left: 0;
    top: -3.5rem;
    height: 100%;
  }

  @include phablet {
    height: calc(100% + 5rem);
    top: -5rem;

    &--left {
      // display: none;

      & ~ .inner-container {
        gap: 5rem;
      }

      width: 50%;

      top: 0;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
    }
  }
}

.section-active-manage {
  .inner-container .left {
    margin-top: -6.3rem;

    @include tablet-portrait {
      margin-top: 0;
    }
  }
}
