body #root .hcp-popup-header {
  width: 100%;
  height: 70rem;
  background-color: var(--bunov-green);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    position: absolute;
    top: 0;
    left: 0;
    padding: 3rem 6rem;
    background-color: var(--bunov-white);
    justify-content: center;
    align-items: center;

    img {
      max-width: 25rem;
    }
  }

  .inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .popup-circles {
      position: absolute;
      top: 0;
      left: 0;
      width: 572px;
      max-width: 100%;
    }
  }

  h1 {
    text-align: center;
    font-size: 4.8rem;
    line-height: 5.8rem;
    font-weight: 700;
    color: var(--bunov-white);
    font-family: "Inter", sans-serif;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 3rem;

    button {
      font-size: 1.6rem;
    }
  }

  .disclaimer {
    font-size: 1.4rem;
    color: var(--bunov-white);
    margin-top: 1rem;
  }

  .link-container {
    margin-top: 3rem;

    .disclaimer {
      font-size: 1.6rem;
      font-weight: 700;
    }

    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      gap: 2rem;
      margin-top: 1rem;

      li {
        font-size: 1.6rem;
        color: var(--bunov-white);

        a {
          text-decoration: underline;
        }
      }
    }
  }

  @include tablet-portrait {
    height: 77rem;
    padding-top: 8rem;
    .logo {
      padding: 1.5rem 3rem;
      img {
        max-width: 18.6rem;
      }
    }

    h1 {
      font-size: 3.2rem;
      line-height: 4rem;
    }

    .disclaimer {
      font-size: 1.2rem;
      text-align: center;
    }

    .link-container {
      .disclaimer {
        font-size: 1.4rem;
      }

      ul {
        margin-top: 2rem;
        padding: 0;
        gap: 1rem;
        flex-direction: column;
        text-align: center;
      }
    }
  }

  @include mobile {
    h1 {
      br {
        display: none;
      }
    }
  }
}
