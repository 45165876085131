.call-to-action {
  position: relative;
  background-color: var(--bunov-green);
  padding: 8rem 0;
  text-align: center;
  color: var(--bunov-white);
  font-size: 1.6rem;

  .inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
  }

  .title {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    opacity: 0.6;
  }
  .cta-heading {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    line-height: 3.4rem;
  }
  .copy {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 4rem;
  }

  &--white {
    background-color: var(--bunov-white);
  }

  .cta-icon {
    width: 8rem;
    height: 8rem;
    margin-bottom: 2rem;
  }

  @include mobile {
    padding: 6rem 0;
  }

  .circles {
    position: absolute;
    top: 0;
    left: 6.5rem;
  }

  &:not(.with-circles) {
    .circles {
      display: none;
    }
  }
}

body.site-patient {
  .call-to-action {
    color: var(--bunov-black);
    background-color: var(--bunov-beige);

    &--white {
      background-color: var(--bunov-white);
    }
  }

  .cta-heading {
    font-family: "Noto Serif", serif;
    font-weight: 700;
    margin-bottom: 2.5rem;
  }

  .copy {
    p {
      margin-bottom: 2rem;
    }
  }
}
