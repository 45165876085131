.external-popup-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(24, 34, 16, 0.6);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 201;

  .external-popup-inner {
    position: relative;
    width: 646px;
    height: 339px;
    background: var(--bunov-white);
    box-shadow: 0px 4px 56px rgba(24, 34, 16, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
      font-size: 2.8rem;
    }

    p {
      font-size: 1.6rem;
      margin-top: 1.6rem;
      margin-bottom: 3.2rem;
      padding: 0 2rem;
    }

    .button-group {
      display: flex;
      gap: 2rem;

      & ~ p {
        font-size: 1.2rem;
        margin-bottom: 0;
        margin-top: 2rem;
      }
    }

    .btn--outline {
      color: var(--bunov-black);
      border-color: var(--bunov-black);

      &:hover {
        background-color: var(--bunov-white);
        border-color: var(--bunov-orange);
        color: var(--bunov-orange);
      }
    }
  }

  @include mobile {
    .external-popup-inner {
      max-width: 90%;
      height: auto;
      padding: 3rem 2rem;

      h2 {
        font-size: 2rem;
      }

      p {
        font-size: 1.2rem;
      }
    }
  }
}

body.site-patient {
  .external-popup-outer {
    .external-popup-inner {
      .btn--outline {
        &:hover {
          background-color: var(--bunov-white);
          border-color: var(--bunov-purple);
          color: var(--bunov-purple);
        }
      }
    }
  }
}
