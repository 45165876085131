.section {
  position: relative;
  padding: 8rem 0;

  &.direction {
    .container {
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      padding: 0;

      @include tablet-portrait {
        grid-template-columns: 1fr;
        padding: 0 1.5rem;
      }

      .direction-box {
        --backgroundColor: var(--bunov-beige);
        --borderColor: var(--bunov-dark-grey);
        --fontColor: var(--bunov-black);

        position: relative;
        background-color: var(--backgroundColor);
        padding: 5rem 6.4rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        color: var(--fontColor);
        transition: all 0.3s ease;

        a {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
        }

        .content {
          margin: 4rem 0 5rem 0;
        }
        p {
          margin-top: 1.6rem;
          font-size: 1.4rem;
          line-height: 2rem;
          margin-bottom: 0;
        }

        .bottom,
        .top {
          position: relative;
          width: 100%;
          height: 1px;
          background-color: var(--borderColor);
        }

        .top {
          &:after {
            content: "";
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            border: 1px solid var(--borderColor);
            position: absolute;
            transform: translate(-50%, -4.5px);
            left: 50%;
            background-color: var(--backgroundColor);
          }
        }

        &:hover {
          --backgroundColor: var(--bunov-purple);
          --borderColor: var(--bunov-white);
          --fontColor: var(--bunov-white);
        }
      }
    }
  }

  &.how-to-apply {
    .container {
      align-items: center;
    }
    .right {
      .btn {
        margin-top: 1.5rem;
        display: inline-block;
      }
    }

    .left {
      img {
        max-height: 42rem;
      }
    }

    .apply-container {
      display: flex;
      flex-direction: column;

      .box {
        width: 45%;
        height: 180px;
        border: 1px solid #8b997f;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 3rem;
        background-color: #fff;

        @media (max-width: 1050px) {
          height: 150px;
        }

        @include mobile {
          height: 110px;
        }

        img {
          max-width: 100%;
          height: 100%;
          display: block;
        }
      }

      .top {
        display: flex;

        .arrow {
          margin: 0 1.5rem;
          max-width: 3.2rem;
        }
      }

      .centre {
        display: flex;
        justify-content: flex-end;
        margin-right: 25%;

        .arrow {
          margin: 1.5rem 0;
        }
      }

      .bottom {
        display: flex;
        .arrow {
          margin: 0 1.5rem;
          max-width: 3.2rem;
        }
      }
    }
  }

  &.speak-to-doctor,
  &.section-how-do {
    .container {
      align-items: center;
    }
  }

  &.reported-side-effects {
    .left {
      align-items: flex-start;
    }
    p {
      &.with-marker {
        font-weight: bold;
        margin-bottom: 0;

        & + p {
          margin-top: 0;
          margin-left: 2.1rem;
        }

        &:before {
          content: "";
          display: inline-block;
          width: 1rem;
          height: 1rem;
          background-image: url("~/src/img/circle-marker.svg");
          background-repeat: no-repeat;
          margin-right: 1rem;
        }
      }
    }
  }

  &.bunov-and-driving {
    .left {
      align-items: flex-start;
    }

    h3 {
      display: flex;
      gap: 2.4rem;
      margin-bottom: 3.2rem;
      font-size: 2.4rem;
      line-height: 3.2rem;
      font-weight: 700;

      img {
        width: 8rem;
        height: 8rem;
      }
    }
    .white-backdrop {
      p {
        margin-bottom: 1rem;
      }

      ul {
        margin-bottom: 0;

        li {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &--references {
    padding: 3rem 0;
  }

  &--to-remember {
    h2 {
      width: 100%;
      text-align: left;
      margin-bottom: 6.4rem;
      margin-top: 3rem;
    }

    .remember-grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(10rem, 31.3rem));
      justify-content: center;
      gap: 2rem;
      row-gap: 4.8rem;

      @include tablet-portrait {
        justify-content: space-between;
      }

      .item {
        display: flex;
        flex-direction: column;
        text-align: left;

        .purple-icon {
          width: 8rem;
          height: 8rem;
          background-color: var(--bunov-purple);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 2rem;
        }

        p {
          &:nth-of-type(2) {
            margin-top: 1rem;
          }
        }
      }
    }
  }

  &--steps {
    .container {
      margin-bottom: 5.2rem;

      .guide-three {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #8b997f;
        width: 100%;
        padding: 2rem 0;
        background-color: #fff;

        .img-wrapper {
          width: 36rem;
          max-width: 100%;
          img {
            width: 100%;
          }
        }
        .divider {
          width: 1px;
          height: 200px;
          background-color: #8b997f;
        }

        @include tablet-portrait {
          flex-direction: column;
          padding: 2rem;

          .divider {
            height: 1px;
            width: 90%;
          }
        }
      }

      .step-container {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        width: 1094px;
        max-width: 100%;
        margin: 0 auto;
        margin-top: 10rem;

        .step {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: left;

          &__copy {
            width: 600px;
            max-width: 100%;
            padding: 0 1.5rem;

            h4 {
              margin-bottom: 2rem;
            }

            p {
              margin-bottom: 1.5rem;
            }
          }

          .img-wrapper {
            width: 424px;
            max-width: 100%;
            border: 1px solid #8b997f;
            padding: 4.6rem;
            background-color: #fff;
          }

          &--reverse {
            flex-direction: row-reverse;
          }
        }

        @include tablet-portrait {
          .step {
            flex-direction: column;

            .img-wrapper {
              margin-bottom: 2rem;
            }
          }
        }
      }
    }

    .inner-container {
      width: 84.5rem;
      max-width: 100%;
      text-align: left;

      p {
        margin-bottom: 2rem;
      }
    }
  }

  &--guide {
    .beige-backdrop {
      ul {
        padding: 0;
        list-style-type: none;

        li {
          font-weight: bold;
          display: flex;
          gap: 1.5rem;
          &:before {
            display: flex;
            content: "";
            width: 1rem;
            height: 1rem;
            background-image: url("~/src/img/circle-marker.svg");
            background-repeat: no-repeat;
            margin-top: 0.6rem;
          }

          span {
            flex: 1;
          }
        }
      }
    }
  }

  .beige-backdrop,
  .white-backdrop {
    background-color: var(--bunov-beige);
    padding: 4.8rem;

    h3 {
      margin-bottom: 2rem;
    }

    ul {
      li {
        margin-bottom: 1rem;
      }
    }

    p {
      margin-bottom: 0;
    }

    @include tablet-portrait {
      br {
        display: none;
      }
    }
  }

  .white-backdrop {
    background-color: var(--bunov-white);

    &.with-dropshadow {
      box-shadow: 0px 4px 40px rgba(24, 34, 16, 0.04);
    }
  }
}

.pointer {
  &:before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #8a987e;
  }

  &:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 0.1rem;
    background-color: #8a987e;
  }

  &--top {
    &:before {
      top: -0.5rem;
    }

    &:after {
      top: 0rem;
    }
  }

  &--right-50 {
    &:before {
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      left: 25%;
      transform: translateX(calc(50% + 0.7rem));
      width: calc(50% - 0.5rem);
    }
  }

  &--left-33 {
    &:before {
      left: 33%;
      transform: translateX(-33%);
    }
    &:after {
      left: 0;
      width: 33%;
    }
  }

  &--left-25 {
    &:before {
      left: 25%;
      transform: translateX(-25%);
    }
    &:after {
      left: 0;
      width: 25%;
    }
  }
}

main {
  &.page--how {
    .heading--small {
      padding-bottom: 0;

      h1 {
        margin-bottom: 0;
      }

      & ~ section:not(.section--references) {
        padding-top: 3rem;
      }
    }
  }
}
